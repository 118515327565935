<template>
  <main>
    <div class="container">
      <div class="col-md-12 mb-3" style="display: flex; justify-content:flex-end; align-content:flex-end;">
        <div>
          <button type="button" class="btn btn-primary" title="Pre-guardado de registro" v-on:click="saveTemplate()">
            <box-icon name='save' color="white"></box-icon>
          </button>
        </div>
      </div>
      <div class="J-tab-container">
        <div class="J-tab-header">
          <div class="J-tab-nav">
            <div class="J-tab-nav-item" v-for="(tab, i) in tabs" :key="i + 1" :class="{ active: tab.checked }">
              <a href="javascript:void(0)" @click="activeTabs(i)" class="J-tab-nav-link">{{ i + 1 }}</a>
              <div class="J-tab-nav-text" style="font-size: 14px">
                {{ tab.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="J-tab-body">
          <!--Datos Personales-->
          <div v-show="tabs[0].active">
            <form @submit.prevent="activeTabs(1)" class="row J-flex center text-uppercase" autocomplete="off">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p style="color: #1b4f99; font-weight: bold"> DATOS PERSONALES</p>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.name" placeholder="Nombre"
                    required>
                  <label for="floatingInput">Nombre(s) <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.lastname"
                    placeholder="Apellido paterno" required>
                  <label for="floatingInput">Apellido paterno <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.secondlastname"
                    placeholder="Apellido materno" required>
                  <label for="floatingInput">Apellido materno <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="date" class="form-control" id="floatingInput" v-model="state.date"
                    placeholder="Fecha de nacimiento" required>
                  <label for="floatingInput">Fecha De Nacimiento <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.curp" placeholder="CURP">
                  <label for="floatingInput">CURP </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="tel" class="form-control" @keypress="onlyNumber" id="floatingInput" v-model="state.phone"
                    placeholder="Celular" required>
                  <label for="floatingInput">Celular <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <select class="form-select" id="floatingSelect" v-model="state.gender"
                    aria-label="Floating label select example" required>
                    <option value="" selected>Selecciona Una Opción</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Otro">Otro</option>
                  </select>
                  <label for="floatingSelect">GÉNERO <sub class="indice">*</sub> </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.country"
                    placeholder="País de Residencia" required disabled>
                  <label for="floatingInput">País de Residencia <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input list="estados" class="form-control" v-on:change="listMuni(state.estadoRepublica)"
                    v-model="state.estadoRepublica" required>
                  <label for="floatingInput">Estado <sub class="indice">*</sub></label>
                  <datalist id="estados">
                    <option v-for="(item, i) in dataEntidad" :key="i + 1" :value="item.estado">
                      {{ item.estado }}
                    </option>
                  </datalist>
                </div>
              </div>
              <!--Municipio-->
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input list="municipio" class="form-control" v-on:change="listColonia(state.municipaly)"
                    v-model="state.municipaly" required>
                  <label for="floatingInput">Municipio / Alcandía <sub class="indice">*</sub></label>
                  <datalist id="municipio">
                    <option v-for="(item, i) in dataMunicipio" :key="i + 1" :value="item.municipio">
                      {{ item.municipio }}
                    </option>
                  </datalist>
                </div>
              </div>
              <!--Delegaciones o Colonia-->
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input list="colonias" id="" class="form-control" v-model="state.delegation">
                  <label for="floatingInput">Colonia</label>
                  <datalist id="colonias">
                    <option v-for="(item, i) in dataColonia.data" :key="i + 1" :value="item.colonia">
                      {{ item.colonia }}
                    </option>
                  </datalist>
                </div>
              </div>
              <!-- Calle -->
              <!-- <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.avenue" placeholder="Avenida / Calle">
                  <label for="floatingInput">Avenida / Calle</label>
                </div>
              </div> -->
              <!-- número exterior-->
              <!-- <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.exteriorNumber" placeholder="Número Exterior">
                  <label for="floatingInput">Número Exterior</label>
                </div>
              </div> -->
              <!-- Código Postal-->
              <!-- <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber" v-model="state.postalCode" placeholder="Código Postal">
                  <label for="floatingInput">Código Postal</label>
                </div>
              </div> -->
              <!-- Correo -->
              <div class="col-md-3">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" id="floatingInput" v-model="state.email"
                    placeholder="Fecha de nacimiento" required>
                  <label for="floatingInput">Correo Electrónico <sub class="indice">*</sub></label>
                </div>
              </div>
              <div class="col-md-3">
              </div>
              <!-- Botón Siguiente -->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start"></div>
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <!--Datos Escolares-->
          <div v-show="tabs[1].active">
            <form class="row J-flex center" @submit.prevent="activeTabs(2)">
              <div class="row J-flex center">
                <div style="display: flex;justify-content: center; align-items: center;">
                  <p style="color: #1b4f99; font-weight: bold">INFORMACIÓN ESCOLAR</p>
                </div>
              </div>
              <div>
                <div class="row J-flex center">
                  <!--Nombre de la Universidad-->
                  <div class="col-md-4">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="floatingInput" v-model="state.school"
                        placeholder="NOMBRE DE TU UNIVERSIDAD" required>
                      <label for="floatingInput">NOMBRE DE TU UNIVERSIDAD <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <!--Plantel Educativo-->
                  <div class="col-md-4">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="floatingInput" v-model="state.plantel"
                        placeholder="PLANTEL" required>
                      <label for="floatingInput">PLANTEL <sub class="indice">*</sub></label>
                    </div>

                  </div>
                  <!--Carrera Elegida-->
                  <div class="col-md-4">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="floatingInput" v-model="state.career"
                        placeholder="PLANTEL" required>
                      <label for="floatingInput">NOMBRE DE LA CARRERA <sub class="indice">*</sub></label>
                    </div>

                  </div>
                  <!--Años Cursados de la Carrera-->
                  <div class="col-md-4">
                    <div class="form-floating">
                      <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                        v-model="state.yearActual" required="true">
                        <option value="" selected disabled>Seleccione una opción</option>
                        <option value="MedioAño">Medio Año</option>
                        <option value="UnAño">Un Año</option>
                        <option value="AñoyMedio">Año y Medio</option>
                        <option value="DosAños">Dos Años</option>
                        <option value="DosAñosyMedio">Dos Años y Medio</option>
                        <option value="Tres Años">Tres Años</option>
                        <option value="Tres Años y Medio">Tres Años y Medio</option>
                        <option value="Cuatro">Cuatro Años</option>
                        <option value="Cuatro Años y Medio">
                          Cuatro Años y Medio
                        </option>
                        <option value="Cinco">Cinco Años</option>
                        <option value="CincoAñosyMedio">Cincos Años y Medio</option>
                        <option value="SeisAños">Seis Años</option>
                        <option value="SeisAñosyMedio">Seis Años y Medio</option>
                        <option value="SieteAños">Siete Años</option>
                        <option value="SieteAñosyMedio">Siete Años y Medio</option>
                        <option value="+OchoAños">+Ocho Años</option>
                      </select>
                      <label for="floatingSelect">CUANTOS AÑOS LLEVAS ESTUDIANDO <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <!--Duración en Años de la carrera-->
                  <div class="col-md-4">
                    <div class="form-floating">
                      <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                        v-model="state.yearTotal" required="true">
                        <option value="" selected disabled>Seleccione una opción</option>
                        <option value="MedioAño">Medio Año</option>
                        <option value="UnAño">Un Año</option>
                        <option value="AñoyMedio">Año y Medio</option>
                        <option value="DosAños">Dos Años</option>
                        <option value="DosAñosyMedio">Dos Años y Medio</option>
                        <option value="Tres Años">Tres Años</option>
                        <option value="Tres Años y Medio">Tres Años y Medio</option>
                        <option value="Cuatro">Cuatro Años</option>
                        <option value="Cuatro Años y Medio">
                          Cuatro Años y Medio
                        </option>
                        <option value="Cinco">Cinco Años</option>
                        <option value="CincoAñosyMedio">Cincos Años y Medio</option>
                        <option value="SeisAños">Seis Años</option>
                        <option value="SeisAñosyMedio">Seis Años y Medio</option>
                        <option value="SieteAños">Siete Años</option>
                        <option value="SieteAñosyMedio">Siete Años y Medio</option>
                        <option value="+OchoAños">+Ocho Años</option>
                      </select>
                      <label for="floatingSelect">DURACIÓN TOTAL DE TU CARRERA <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <!--Promedio Escolar Actual-->
                  <div class="col-md-4">
                    <div class="J-form-group">
                      <InputPartials @inputChange="state.promedio = $event" :min="1" :max="3" :text="state.promedio"
                        placeholder="Promedio ACTUAL" />
                    </div>
                  </div>
                </div>
              </div>
              <!--Boton ATRAS-->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(0)">
                    Atras
                  </button>
                </div>
                <!--Boton SIGUIENTE-->
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <!--Conocimiento de Idiomas-->
          <div v-show="tabs[2].active">
            <form class="row J-flex center" @submit.prevent="activeTabs(3)">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p class="sub-t-3" style="color: #1b4f99; font-weight: bold;">
                  COMPARTENOS TUS CONOCIMIENTOS EN ALGÚN IDIOMA
                </p>
              </div>
              <!--IDIOMA-->
              <div v-for="(item, ind) in state.idiomas" :key="ind + 1" class="row J-flex center">
                <div style="display: flex; justify-content: space-between;">
                  <p style="color: #1b4f99; font-weight: bold">IDIOMA {{ ind + 1 }}</p>
                  <box-icon name='trash' v-show="ind + 1 > 1" v-on:click="deleteArray(ind, 'idiomas')"
                    style="cursor: pointer"></box-icon>
                </div>
                <!--Selección de Idioma-->
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="item.idioma">
                      <option value="" selected disabled>
                        -- Seleccione --
                      </option>
                      <option v-for="(item, i) in lenguagesData" :key="i + 1" :value="item.name">{{ item.name }}
                      </option>
                    </select>
                    <label for="floatingSelect">IDIOMA {{ ind + 1 }}</label>
                  </div>
                </div>
                <!--Nivel de Idioma-->
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="item.nivel">
                      <option value="" selected disabled>
                        -- Seleccione --
                      </option>
                      <option value="Basico">Básico</option>
                      <option value="Intermedio">Intermedio</option>
                      <option value="Avanzado">Avanzado</option>
                    </select>
                    <label for="floatingSelect">NIVEL EN EL IDIOMA</label>
                  </div>
                </div>
                &nbsp; &nbsp;
              </div>
              <!--Boton para agregar un idioma diferente-->
              <div class="col-4 J-flex start mt-3" v-if="state.idiomas.length < 10">
                <button class="J-btn J-btn-secondary" type="button" @click="agregateLenguage()">
                  Agregar otro idioma
                </button>
              </div>
              <!--Botones Atras y Siguiente-->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(1)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary" type="submit">
                    Siguiente
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!--Experiencia Laboral-->
          <div v-show="tabs[3].active">
            <form class="row J-flex center" @submit.prevent="activeTabs(4)" autocomplete="off">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p style="color: #1b4f99; font-weight: bold">
                  EXPERIENCIA PROFESIONAL
                </p>
              </div>
              <div class="row J-flex center">
                <div class="col-md-4">
                  <div class="form-check form-switch">
                    <label class="form-check-label" for="flexSwitchCheckDefault">¿TIENES EXPERIENCIA LABORAL?</label>
                    <input class="form-check-input" v-model="state.experienciaPrevia" type="checkbox" role="switch"
                      id="flexSwitchCheckDefault">
                  </div>
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
              </div>
              <div v-if="state.experienciaPrevia">
                <div class="row J-flex center" v-for="(exp, ind) in state.experiencia" :key="ind + 1">
                  &nbsp; &nbsp;
                  <div style=" display: flex; justify-content: space-between; ">
                    <p style="text-aling: center; color: rgb(27, 79, 153); font-weight: bold;">
                      {{ exp.experienciaId }}
                    </p>
                    <box-icon name='trash' v-show="ind + 1 > 1" v-on:click="deleteArray(ind, 'experiencia')"
                      style="cursor: pointer"></box-icon>
                  </div>

                  <div class="col-md-3">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="floatingInput" v-model="exp.experienciaCompany"
                        placeholder="EMPRESA" required>
                      <label for="floatingInput">EMPRESA <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="floatingInput" v-model="exp.experienciaPuesto"
                        placeholder="PUESTO" required>
                      <label for="floatingInput">PUESTO <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="floatingInput" v-model="exp.experienciaSalario"
                        placeholder="$ SUELDO MENSUAL" @keypress="onlyNumberSalary" required>
                      <label for="floatingInput">$<small>SUELDO MENSUAL</small> <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-floating mb-2">
                      <input type="date" class="form-control" id="floatingInput" v-model="exp.experienciaFechaInicio"
                        placeholder="Fecha de ingreso" required :active="true">
                      <label for="floatingInput">Fecha de ingreso <sub class="indice">*</sub></label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-floating mb-2">
                      <input type="date" class="form-control" id="floatingInput" v-model="exp.experienciaFechaFin"
                        placeholder="Fecha de salida" :active="true" required>
                      <label for="floatingInput">Fecha de salida <sub v-show="exp.requiredValue"
                          class="indice">*</sub></label>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-floating">
                      <textarea v-model="exp.experecienciaLogros" class="form-control"
                        placeholder="Leave a comment here" id="floatingTextarea" style="height: 240px"
                        :required="exp.requiredValue"></textarea>
                      <label for="floatingTextarea">PRINCIPALES FUNCIONES/LOGROS <sub class="indice">*</sub></label>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start" style="margin-left: 3%">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(2)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-secondary" type="button" @click="addExperiencia()"
                    :disabled="ind + 1 == 5 || state.experienciaPrevia == false">
                    Agregar otro empleo
                  </button>
                </div>
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-secondary" type="submit">
                    Siguiente
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!--Intereses genrales o Hobbies-->
          <div v-show="tabs[4].active">
            <form class="row J-flex center text-uppercase" @submit.prevent="activeTabs(5)" autocomplete="off">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p style="color: #1b4f99; font-weight: bold">
                  INTERESES GENERALES
                </p>
              </div>
              <div class="J-flex wrap center mt-5">
                <div class="col-md-3  mt-3  ps-3" v-for="(item, ind) in state.hobbies" :key="ind + 1">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div class="form-floating">
                      <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                        v-model="item.name" required>
                        <option v-for="(item, i) in dataHabilidades" :key="i + 1" :value="item.name">{{ item.name }}
                        </option>
                      </select>
                      <label for="floatingSelect">Hobbie {{ ind + 1 }} <sub class="indice">*</sub></label>
                    </div>
                    &nbsp;
                    <div>
                      <box-icon name='trash' v-show="ind + 1 > 1" v-on:click="deleteArray(ind, 'hobbies')"
                        style="cursor: pointer"></box-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 J-flex ps-3  mt-3 center" v-if="state.hobbies.length < 10">
                  <button class="J-btn J-btn-secondary" type="button" @click="agregateSports()">
                    Agregar otro hobbie
                  </button>
                </div>
              </div>
              &nbsp; &nbsp;
              <div style="display: flex; justify-content: center; align-items: center;">
                <p style="color: #1b4f99; font-weight: bold">
                  SI LOS HOBBIES NO SE ENCUENTRAN EN LA LISTA, FAVOR DE AGREGARLOS
                </p>
              </div>
              <div class="J-flex center mt-2">
                <div class="col-md-6">
                  <div class="J-form-group">
                    <InputPartials @inputChange="state.hobbieWrite1 = $event" :min="3" :max="255"
                      :text="state.hobbieWrite1" placeholder="Escribe un hobbie" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="J-form-group">
                    <InputPartials @inputChange="state.hobbieWrite2 = $event" :min="3" :max="255"
                      :text="state.hobbieWrite2" placeholder="Escribe un hobbie" />
                  </div>
                </div>
              </div>
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(3)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-secondary" type="submit">
                    Siguiente
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!--Red Social (LinkedIn)-->
          <div v-show="tabs[5].active">
            <form class="row J-flex center" @submit.prevent="activeTabs(6)">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p style="color: #1b4f99; font-weight: bold">
                  COMPARTE CON NOSOTROS TU PERFIL DE LINKEDIN
                </p>
              </div>
              <div class="row J-flex center">
                <div class="col-md-4">
                  <div class="form-check form-switch">
                    <label class="form-check-label" for="flexSwitchCheckDefault">¿Deseas agregar tu perfil de LinkedIn?</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" @change="editInf">
                  </div>
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="" v-for="(social, i) in socialMedia" :key="i + 1">
                  <label class="">
                    <span class="J-social-media-text">{{ social.title }}</span>
                    <span class="J-social-media-icon" :class="social.title.toLowerCase()">
                      <i :class="social.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
              <!-- nuevo -->
              <div class="col-6" v-if="enableInput">
                <div style="display: flex; justify-content: center; align-content: center;">
                  <span class="input mb-2" id="basic-addon3" style="text-align: center">Ejemplo:
                    https://www.linkedin.com/company/hr-five-group/</span>
                </div>
                <div class="J-form-group">
                  <InputPartials :text="state.linkedin" @inputChange="state.linkedin = $event" />
                </div>
              </div>
              <!-- Botones -->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(4)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary">
                    Siguiente
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!--Comprobante de Estudios-->
          <div v-show="tabs[6].active">
            <form class="row J-flex center" @submit.prevent="submitForm()">
              <div class="J-flex center row">
                <!--Subir Archivo-->
                <div class="col-md-4">
                  <UploadPartials title="SUBIR CV" @data="changeCv" required/>
                </div>
                <!-- <div class="col-md-4">
                  <UploadPartials title="SUBIR COMPROBANTE DE ESTUDIOS" @data="changeComprobante" />
                </div> -->
                <!--Subir Archivo-->

                <!--Aceptación terminos y condiciones-->
                <div class="col-md-12 J-flex center mt-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" v-model="state.terms" type="checkbox" role="switch" id="terms"
                      checked required />
                    <a href="https://mundouniversitario.com/avisodeprivacidad" class="form-check-label" for="terms"
                      target="_blank" style="color: black;" required>ACEPTO TÉRMINOS Y CONDICIONES</a>
                  </div>
                </div>
              </div>
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(5)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex end">
                  <button type="submit" class="J-btn J-btn-secondary" v-on:click="submitForm()"
                    :disabled="response.loading === true">
                    Enviar
                    <div class=" spinner-border text-light J-spinner" role="status" v-show="response.loading === true">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import InputPartials from "@/components/partials/Input.partials.vue";
import UploadPartials from "@/components/partials/upload.partials.vue";
import { UserService } from "@/services";

export default {
  mounted() {
    const email = localStorage.getItem("email");
    this.state.email = email;
  },
  created() {
    this.listEstado();
    const user = JSON.parse(localStorage.getItem("user") || null);
    const { templateForm } = user;
    if (templateForm === "") {
      let returnedTarget = this.state;
      console.log(returnedTarget);
    } else {
      const returnedTarget = Object.assign(this.state, JSON.parse(templateForm));
      console.log(returnedTarget);
    }
  },
  data() {
    return {
      state: {
        //TAB 1
        name: "",  //Pre-Universitario, Universitario, Ex-Universitario
        lastname: "",  //Pre-Universitario, Universitario, Ex-Universitario
        secondlastname: "",  //Pre-Universitario, Universitario, Ex-Universitario
        date: "",  //Pre-Universitario, Universitario, Ex-Universitario
        curp: "",  //Pre-Universitario, Universitario, Ex-Universitario
        gender: "", //Pre-Universitario, Universitario, Ex-Universitario
        phone: "",  //Pre-Universitario, Universitario, Ex-Universitario
        country: "México",  //Pre-Universitario, Universitario, Ex-Universitario
        estadoRepublica: "",  //Pre-Universitario, Universitario, Ex-Universitario
        municipaly: "", //Pre-Universitario, Universitario, Ex-Universitario
        delegation: "", //Pre-Universitario, Universitario, Ex-Universitario
        avenue: "",
        exteriorNumber: "",
        postalCode: "",
        email: "",  //Pre-Universitario, Universitario, Ex-Universitario
        //TAB 2
        actuallyStudying: "", //Pre-Universitario y Universitario
        school: "", //Pre-Universitario y Universitario
        plantel: "", //Pre-Universitario y Universitario
        career: "", //Pre-Universitario y Universitario
        yearActual: "", //Pre-Universitario y Universitario
        yearTotal: "",  //Pre-Universitario y Universitario
        promedio: "", //Pre-Universitario y Universitario
        //TAB 3
        idiomas: [  //Pre-Universitario, Universitario, Ex-Universitario
          {
            idioma: '',
            nivel: ''
          }
        ],
        //TAB 4 
        experienciaPrevia: "",  //Pre-Universitario, Universitario, Ex-Universitario
        experiencia: [  //Pre-Universitario, Universitario, Ex-Universitario
          {
            experienciaId: "Empleo",
            experienciaCompany: "",
            experienciaPuesto: "",
            experienciaSalario: "",
            experienciaFechaInicio: "",
            experienciaFechaFin: "",
            experienciaLogros: "",
            requiredValue: false,
          },
        ],
        //TAB 5
        hobbies: [  //Pre-Universitario, Universitario, Ex-Universitario
          {
            name: ""
          }
        ],
        hobbieWrite1: "", //Pre-Universitario, Universitario, Ex-Universitario
        hobbieWrite2: "", //Pre-Universitario, Universitario, Ex-Universitario
        //TAB 6
        linkedin: "", //Pre-Universitario, Universitario, Ex-Universitario
        //TAB 7
        terms: "false", //Pre-Universitario, Universitario, Ex-Universitario
        status: "", //Pre-Universitario, Universitario, Ex-Universitario
      },
      tempSkill: "",
      filecv: "",
      filecomprobantes: "",
      counter: 1,
      enableInput: false,
      // experiencia: [
      //   {
      //     experienciaId: "EMPLEO ACTUAL",
      //     experienciaPrevia: "¿Tienes Experiencia previa?",
      //     experienciaCompany: "Empresa",
      //     experienciaPuesto: "Puesto",
      //     experienciaSalario: "$ SUELDO MENSUAL",
      //     experienciaFechaInicio: "Fecha de Ingreso",
      //     experienciaFechaFin: "Fecha de Salida",
      //     experienciaLogros: "PRINCIPALES FUNCIONES/LOGROS",
      //   },
      // ],
      dataColonia: [],
      dataMunicipio: [],
      dataEntidad: [],
      response: {
        error: false,
        loading: false,
        success: false,
        message: "",
      },
      dataHabilidades: [
        {
          "name": "ABEJAS (CRIANZA DE ABEJAS O APICULTURA)"
        },
        {
          "name": "ACROYOGA"
        },
        {
          "name": "ACTUAR EN TEATRO"
        },
        {
          "name": "ACUARIOS"
        },
        {
          "name": "AEROBIC"
        },
        {
          "name": "AEROMODELISMO"
        },
        {
          "name": "AEROSTACION"
        },
        {
          "name": "AIKIDO"
        },
        {
          "name": "AIRSOFT"
        },
        {
          "name": "AJEDREZ"
        },
        {
          "name": "ANIMACION ( CREACION DE CINE DE ANIMACION Y DIBUJOS ANIMADOS)"
        },
        {
          "name": "AQUAGYM"
        },
        {
          "name": "AROMATERAPIA"
        },
        {
          "name": "ARTE (AFICION A LAS ARTES PLASTICAS)"
        },
        {
          "name": "ARTE DIGITAL"
        },
        {
          "name": "ARTE:ASISTIR A ESPECTACULOS CULTURALES (TEATRO,MUSICA,DANZA,OPERA,ZARZUELA)"
        },
        {
          "name": "ASOCIACIONES (PERTENENCIA A GRUPOS SOCIALES DE TIPO ARTISTICO,DEPORTIVO,FESTIVO,ETC)"
        },
        {
          "name": "ASTROLOGIA"
        },
        {
          "name": "ASTRONOMIA"
        },
        {
          "name": "ATLETISMO"
        },
        {
          "name": "AUDIOLIBROS"
        },
        {
          "name": "AUTOCARAVANAS"
        },
        {
          "name": "AUTOMOVILES CLASICOS/ANTIGUOS"
        },
        {
          "name": "AVENTURA( ACTIVIDADES DE )"
        },
        {
          "name": "AVIACION DEPORTIVA (Y RECREATIVA)"
        },
        {
          "name": "AVICULTURA (CRIA DE GALLINAS)CASERA"
        },
        {
          "name": "AVISTAMIENTO DE AVES"
        },
        {
          "name": "BACKGAMMON"
        },
        {
          "name": "BADMINTON"
        },
        {
          "name": "BAILAR"
        },
        {
          "name": "BAILE CON BARRA (POLE DANCE, POLE FITNESS)"
        },
        {
          "name": "BALLET"
        },
        {
          "name": "BALONCESTO"
        },
        {
          "name": "BALONMANO"
        },
        {
          "name": "BALONMANO PLAYA"
        },
        {
          "name": "BANDA O GRUPO MUSICALES"
        },
        {
          "name": "BARCO DENTRO DE UNA BOTELLA"
        },
        {
          "name": "BARCOS DE MOTOR"
        },
        {
          "name": "BARRANQUISMO"
        },
        {
          "name": "BATALLAS DE GALLOS"
        },
        {
          "name": "BATIK Y ESTAMPACION DE TEJIDOS"
        },
        {
          "name": "BEISBOL"
        },
        {
          "name": "BELLEZA Y ESTETICA (AFICION A)"
        },
        {
          "name": "BIATLON"
        },
        {
          "name": "BIBLIOFILIA"
        },
        {
          "name": "BICICLETA (CICLISMO)"
        },
        {
          "name": "BICICLETA DE MONTAÑA"
        },
        {
          "name": "BIKEJORING"
        },
        {
          "name": "BILLAR"
        },
        {
          "name": "BINGO"
        },
        {
          "name": "BLOG (Y VIDEOBLOG)"
        },
        {
          "name": "BMX (BICICLETA DE MOTOCROSS)"
        },
        {
          "name": "BODYBOARD"
        },
        {
          "name": "BOLOS (BOWLING, BOLICHE)"
        },
        {
          "name": "BONSAI (ARBOLES ENANOS)"
        },
        {
          "name": "BORDADO SOBRE PAPEL"
        },
        {
          "name": "BOXEO"
        },
        {
          "name": "BRICOLAJE O DIY"
        },
        {
          "name": "BUCEO (SUBMARINISMO)"
        },
        {
          "name": "BUCEO» EN CONTENEDORES"
        },
        {
          "name": "CABALLO (MONTAR, HIPICA, EQUITACION)"
        },
        {
          "name": "CALIGRAFIA ARTISTICA Y LETTERING"
        },
        {
          "name": "CALISTENIA"
        },
        {
          "name": "CAMPISMO"
        },
        {
          "name": "CAMPO A TRAVES"
        },
        {
          "name": "CANARICULTURA"
        },
        {
          "name": "CANICROSS"
        },
        {
          "name": "CANTAR"
        },
        {
          "name": "CAPOEIRA"
        },
        {
          "name": "CARPINTERIA"
        },
        {
          "name": "CARRERAS POR MONTAÑA"
        },
        {
          "name": "CARROVELA Y BLOKART (LAND SAILING)"
        },
        {
          "name": "CASAS DE MUÑECAS (CONSTRUCCION DE)"
        },
        {
          "name": "CASTILLOS Y FORTIFICACIONES (INTERES POR)"
        },
        {
          "name": "CATA DE CERVEZA"
        },
        {
          "name": "CATA DE VINO"
        },
        {
          "name": "CAZA"
        },
        {
          "name": "CERAMICA"
        },
        {
          "name": "CERVEZA (FABRICACION CASERA DE)"
        },
        {
          "name": "CESTERIA"
        },
        {
          "name": "CETRERIA"
        },
        {
          "name": "CHALKPAINT (PINTURA DE TIZA)"
        },
        {
          "name": "CHI KUNG O QUI GONG"
        },
        {
          "name": "CIANOTIPIA"
        },
        {
          "name": "CICLOTURISMO"
        },
        {
          "name": "CIENCIA (AFICION A LA)"
        },
        {
          "name": "CINE (AFICION AL CINE, VER CINE)"
        },
        {
          "name": "COCINA"
        },
        {
          "name": "COLECCIONISMO (SELLOS,MONEDAS,ANTIGÜEDADES,ETC)"
        },
        {
          "name": "COLOREAR LIBROS"
        },
        {
          "name": "COMETAS (VOLAR COMETAS)"
        },
        {
          "name": "COMICS (CREACION DE )"
        },
        {
          "name": "COMICS (LECTURA DE)"
        },
        {
          "name": "COMIDISTA (FOODIE)"
        },
        {
          "name": "COMPOSICION MUSICAL"
        },
        {
          "name": "COMPOSTAJE"
        },
        {
          "name": "COMPRAR (O VENDER: ROPA, COMPLEMENTOS, OBJETOS, ETC)"
        },
        {
          "name": "CONDUCCIÓN DE AUTOMÓVILES (AUTOMOVILISMO DEPORTIVO)"
        },
        {
          "name": "CONDUCCION DE MOTOCICLETAS (MOTOCICLISMO)Y MOTEROS"
        },
        {
          "name": "CONFERENCIAS(ASISTIR A)"
        },
        {
          "name": "CORO"
        },
        {
          "name": "CORRER (RUNNING)"
        },
        {
          "name": "COSMETICA CASERA"
        },
        {
          "name": "COSPLAY"
        },
        {
          "name": "COSTURA, CORTE Y CONFECCION"
        },
        {
          "name": "CRISTAL TEÑIDO"
        },
        {
          "name": "CROQUET"
        },
        {
          "name": "CROSSFIT"
        },
        {
          "name": "CRUCEROS (VIAJES DE)"
        },
        {
          "name": "CUBO DE RUBIK"
        },
        {
          "name": "CUERO (CREACIONES CON)"
        },
        {
          "name": "CULTURISMO (BODY BUILDING)"
        },
        {
          "name": "CURLING"
        },
        {
          "name": "CUSTOMIZACION DE ROPA"
        },
        {
          "name": "CUSTOMIZACION Y RESTAURACION DE BICICLETAS"
        },
        {
          "name": "DAMAS (JUEGO DE)"
        },
        {
          "name": "DANZA AEREA"
        },
        {
          "name": "DARDOS (LANZAR DARDOS)"
        },
        {
          "name": "DECORACION DE INTERIORES"
        },
        {
          "name": "DECOUPAGE (DECORACION DE SUPERFICIES)"
        },
        {
          "name": "DEPORTE (AFICION AL)"
        },
        {
          "name": "DEPORTE (ASISTIR A ESPECTACULOS DEPORTIVOS)"
        },
        {
          "name": "DEPORTE (VER DEPORTE EN TV, ONLINE, REVISTAS O PERIODICOS, RADIO)"
        },
        {
          "name": "DEPORTES DE FANTASIA (LIGA DE FANTASIA)"
        },
        {
          "name": "DIARIO (ESCRIBIR UN)"
        },
        {
          "name": "DIBUJO ARTISTICO"
        },
        {
          "name": "DIORAMAS (Y BELENES)"
        },
        {
          "name": "DIRIGIR, ENTRENAR, GESTIONAR"
        },
        {
          "name": "DISC GOLF"
        },
        {
          "name": "DISEÑO DE ROPA (MODA)"
        },
        {
          "name": "DISEÑO Y CREACION DE PAGINAS WEB"
        },
        {
          "name": "DJ (DISK JOCKEY)"
        },
        {
          "name": "DOCUMENTALES (AFICION A LOS)"
        },
        {
          "name": "DOMINO"
        },
        {
          "name": "ELECTRONICA"
        },
        {
          "name": "ENCUADERNACION DE LIBROS"
        },
        {
          "name": "ENMARCAR CUADROS"
        },
        {
          "name": "ENTOMOLOGIA (AFICION A LA)"
        },
        {
          "name": "ESCALADA"
        },
        {
          "name": "ESCAPE ROOM"
        },
        {
          "name": "ESCRIBIR LITERATURA (PROSA,POESIA,TEATRO,"
        },
        {
          "name": "ENSAYO,GUIONES)"
        },
        {
          "name": "ESCULTURA"
        },
        {
          "name": "ESGRIMA"
        },
        {
          "name": "ESMALTES SOBRE METAL (AL FUEGO)"
        },
        {
          "name": "ESPELEOLOGIA"
        },
        {
          "name": "ESPORTS"
        },
        {
          "name": "ESQUI ALPINO"
        },
        {
          "name": "ESQUI DE FONDO O NORDICO"
        },
        {
          "name": "ESQUI NAUTICO O ACUATICO"
        },
        {
          "name": "ESTAMPACION BOTANICA"
        },
        {
          "name": "ESTUDIAR (IDIOMAS, OTRAS ASIGNATURAS,ETC)"
        },
        {
          "name": "EXPLORACION URBANA (URBEX)"
        },
        {
          "name": "FLAMENCO"
        },
        {
          "name": "FLOORBALL (O UNIHOCKEY)"
        },
        {
          "name": "FLORES KANZASHI"
        },
        {
          "name": "FLORES SECAS (TRABAJAR CON)"
        },
        {
          "name": "FLYBOARD"
        },
        {
          "name": "FOSILES (BUSQUEDA DE)"
        },
        {
          "name": "FOTOGRAFIA"
        },
        {
          "name": "FRISBEE (DISCO VOLADOR)"
        },
        {
          "name": "FRONTON"
        },
        {
          "name": "FUTBOL"
        },
        {
          "name": "FUTBOL AMERICANO"
        },
        {
          "name": "FUTBOL SALA"
        },
        {
          "name": "FUTBOLIN"
        },
        {
          "name": "GENEALOGIA E HISTORIA FAMILIAR"
        },
        {
          "name": "GEOCACHING Y BUSQUEDA DE TESOROS"
        },
        {
          "name": "GIMNASIA (ARTISTICA,RITMICA,)"
        },
        {
          "name": "GIMNASIA DE MANTENIMIENTO Y FITNESS"
        },
        {
          "name": "GLOBOS (TRABAJO CON GLOBOS MOLDEABLES)"
        },
        {
          "name": "GO (JUEGO)"
        },
        {
          "name": "GOLF"
        },
        {
          "name": "GRABADOS ARTISTICOS"
        },
        {
          "name": "GRAFFITI Y ARTE URBANO"
        },
        {
          "name": "GROUNDHOPPING («IR DE ESTADIO EN ESTADIO»)"
        },
        {
          "name": "HACER VINO"
        },
        {
          "name": "HALTEROFILIA (LEVANTAMIENTO DE PESAS)"
        },
        {
          "name": "HAPKIDO"
        },
        {
          "name": "HIDROPONIA (CULTIVO EN LIQUIDO)"
        },
        {
          "name": "HISTORIA (AFICION A LA)"
        },
        {
          "name": "HOCKEY DE MESA (AIR HOCKEY)"
        },
        {
          "name": "HOCKEY SOBRE HIELO"
        },
        {
          "name": "HOCKEY SOBRE HIERBA"
        },
        {
          "name": "HOCKEY SOBRE PATINES"
        },
        {
          "name": "HOCKEY SUBACUATICO"
        },
        {
          "name": "HORTICULTURA (AGRICULTURA)"
        },
        {
          "name": "HUERTO CASERO"
        },
        {
          "name": "HYDROSPEED"
        },
        {
          "name": "IKEBANA ( Y ARTE FLORAL)"
        },
        {
          "name": "INTERNET (NAVEGAR POR)"
        },
        {
          "name": "INVERTIR EN BOLSA"
        },
        {
          "name": "JABON (HACER)"
        },
        {
          "name": "JARDINERIA"
        },
        {
          "name": "JET SURF"
        },
        {
          "name": "JIU JITSU"
        },
        {
          "name": "JOYAS Y BISUTERIA (CREACION DE)"
        },
        {
          "name": "JUDO"
        },
        {
          "name": "JUEGOS CON DISP"
        },
        {
          "name": "OSITIVOS ELECTRONICOS (PC, TABLET, MOVIL)"
        },
        {
          "name": "JUEGOS CON FICHAS (GENERAL)"
        },
        {
          "name": "JUEGOS DE CARTAS O NAIPES"
        },
        {
          "name": "JUEGOS DE MESA (GENERAL)"
        },
        {
          "name": "JUEGOS DE MESA TEMATICO"
        },
        {
          "name": "JUEGOS DE ROL"
        },
        {
          "name": "JUGGER"
        },
        {
          "name": "KANGOO JUMP"
        },
        {
          "name": "KARAOKE"
        },
        {
          "name": "KARATE"
        },
        {
          "name": "KAYAK POLO"
        },
        {
          "name": "KENDO"
        },
        {
          "name": "KENPO"
        },
        {
          "name": "KICK BOXING"
        },
        {
          "name": "KINTSUGI"
        },
        {
          "name": "KITESURF"
        },
        {
          "name": "KOBUDO"
        },
        {
          "name": "KOKEDAMAS (PLANTAS SOBRE MUSGO)"
        },
        {
          "name": "KORFBALL"
        },
        {
          "name": "KUNG FU"
        },
        {
          "name": "LABORES (PUNTO,CROCHET, BORDADO, ENCAJE,ETC)"
        },
        {
          "name": "LASER COMBAT"
        },
        {
          "name": "LECTURA"
        },
        {
          "name": "LEGO (CONSTRUCCIONES CON PIEZAS)"
        },
        {
          "name": "LIBROS POP UP (CREACION DE)"
        },
        {
          "name": "LIMPIEZA DE AUTOMOVILES («DETAILING»)"
        },
        {
          "name": "LOCK PICKING (ABRIR CERRADURAS)"
        },
        {
          "name": "LOMOGRAFIA"
        },
        {
          "name": "LOTERIA"
        },
        {
          "name": "LUCHA (OLIMPICA, GRECORROMANA)"
        },
        {
          "name": "MADERA (TALLAS EN MADERA)"
        },
        {
          "name": "MAGIA (ILUSIONISMO, PRESTIDIGITACION)"
        },
        {
          "name": "MAHJONG (JUEGO DE FICHAS CHINO)"
        },
        {
          "name": "MALABARES"
        },
        {
          "name": "MANGA Y ANIME"
        },
        {
          "name": "MANUALIDADES (TRABAJOS MANUALES)"
        },
        {
          "name": "MARCHA O PASEO NORDICO (NORDIC WALKING)"
        },
        {
          "name": "MARIONETAS (TEATRO DE)"
        },
        {
          "name": "MEDITACION"
        },
        {
          "name": "MERCADILLOS/FLEA MARKETS"
        },
        {
          "name": "MERMELADAS (PREPARAR)"
        },
        {
          "name": "METALES (BUSQUEDA DE)"
        },
        {
          "name": "METEOROLOGÍA (AFICIÓN A LA)"
        },
        {
          "name": "MINDFULNESS O ATENCIÓN PLENA"
        },
        {
          "name": "MINERALES (BUSQUEDA DE)"
        },
        {
          "name": "MODA (AFICION A LA MODA)"
        },
        {
          "name": "MODELISMO (AVIONES,COCHES,BARCOS,DRONES, FIGURAS,ETC)"
        },
        {
          "name": "MODELISMO CON CERILLAS"
        },
        {
          "name": "MONTAÑISMO/ALPINISMO"
        },
        {
          "name": "MOTO ACUATICA"
        },
        {
          "name": "MOTOR (AFICION A TEMAS DEL)"
        },
        {
          "name": "MUÑECAS (HACER)"
        },
        {
          "name": "MUSHING"
        },
        {
          "name": "MUSICA (AFICION A LA)"
        },
        {
          "name": "NADAR (NATACION)"
        },
        {
          "name": "NATURALEZA (INTERES POR LA)"
        },
        {
          "name": "OBSERVACION DE TRENES Y AVIONES"
        },
        {
          "name": "ORIENTACION"
        },
        {
          "name": "ORIGAMI (O PAPIROFLEXIA)"
        },
        {
          "name": "PADBOL"
        },
        {
          "name": "PADDLE SURF (SURF A REMO)"
        },
        {
          "name": "PADEL"
        },
        {
          "name": "PAINTBALL"
        },
        {
          "name": "PALOMAS (CRIA DE PALOMAS, COLOMBOFILIA Y COLOMBICULTURA)"
        },
        {
          "name": "PAPEL MACHE"
        },
        {
          "name": "PARACAIDISMO"
        },
        {
          "name": "PARAMOTOR"
        },
        {
          "name": "PARAPENTE"
        },
        {
          "name": "PARKOUR (FREERUNNING)"
        },
        {
          "name": "PASATIEMPOS (CRUCIGRAMAS, SUDOKUS, INFANTILES, MEMORIA, ETC)"
        },
        {
          "name": "PASEAR"
        },
        {
          "name": "PATCHWORK Y COLCHAS"
        },
        {
          "name": "PATINAJE SOBRE HIELO"
        },
        {
          "name": "PATINAJE SOBRE RUEDAS (ROLLER)"
        },
        {
          "name": "PATINETE ELECTRICO"
        },
        {
          "name": "PERFUME CASERO"
        },
        {
          "name": "PERIODISMO AFICIONADO"
        },
        {
          "name": "PERROS (CONCURSOS/EXHIBICIONES)"
        },
        {
          "name": "PESCA"
        },
        {
          "name": "PESCA SUBMARINA"
        },
        {
          "name": "PETANCA"
        },
        {
          "name": "PICKLEBALL"
        },
        {
          "name": "PILATES"
        },
        {
          "name": "PING PONG O TENIS DE MESA"
        },
        {
          "name": "PINTURA ARTISTICA"
        },
        {
          "name": "PINTURA DE FIGURAS (POLICROMADO,DORADO)"
        },
        {
          "name": "PINTURA SOBRE SEDA"
        },
        {
          "name": "PIRAGÜISMO (CANOISMO/CANOTAJE/KAYAKISMO)"
        },
        {
          "name": "PLANTAS DE INTERIOR"
        },
        {
          "name": "PLANTAS SILVESTRES, INCLUIDOS ARBOLES Y ARBUSTOS (AFICION A LAS)"
        },
        {
          "name": "PODCASTS (AFICION/CREACION)"
        },
        {
          "name": "POLO"
        },
        {
          "name": "PORCELANA FRIA"
        },
        {
          "name": "POWERLIFTING"
        },
        {
          "name": "PRODUCCION MUSICAL"
        },
        {
          "name": "PROGRAMACION INFORMATICA"
        },
        {
          "name": "PUROS HABANOS(CATA DE)"
        },
        {
          "name": "PUZZLES"
        },
        {
          "name": "QUAD Y BUGGY"
        },
        {
          "name": "RADIO (AFICION A LA)"
        },
        {
          "name": "RADIOAFICION"
        },
        {
          "name": "RAFTING"
        },
        {
          "name": "RAPEL"
        },
        {
          "name": "RAQUETAS DE NIEVE"
        },
        {
          "name": "REALIDAD VIRTUAL (JUEGOS DE)"
        },
        {
          "name": "RECICLAJE CREATIVO"
        },
        {
          "name": "REDES SOCIALES (FACEBOOK, YOUTUBE,TWITTER,INSTAGRAM,ETC)"
        },
        {
          "name": "REIKI (TECNICA ESPIRITUAL SANADORA)"
        },
        {
          "name": "REMO DEPORTIVO"
        },
        {
          "name": "REPOSTERIA/PASTELERIA Y CUPCAKES"
        },
        {
          "name": "REPUJADO DE METAL"
        },
        {
          "name": "RESTAURACION DE COCHES CLASICOS"
        },
        {
          "name": "RESTAURACION DE MUEBLES"
        },
        {
          "name": "REVISTAS Y PERIODICOS (LEER)"
        },
        {
          "name": "ROBOTICA"
        },
        {
          "name": "ROLLER DERBY"
        },
        {
          "name": "ROLLERSKI Y CROSS SKATING"
        },
        {
          "name": "RUGBY"
        },
        {
          "name": "SALUD (INTERES POR TEMAS DE SALUD)"
        },
        {
          "name": "SCALEXTRIC"
        },
        {
          "name": "SCRAPBOOK (ALBUM DE RECORTES)"
        },
        {
          "name": "SENDERISMO"
        },
        {
          "name": "SERIES DE TV (AFICION A LAS SERIES)"
        },
        {
          "name": "SERIGRAFIA"
        },
        {
          "name": "SETAS (BUSQUEDA O RECOLECCIÓN DE)"
        },
        {
          "name": "SKATEBOARDING"
        },
        {
          "name": "SLIME (JUGAR CON SLIME, UNA MASA VISCOSA )"
        },
        {
          "name": "SNOWBIKE"
        },
        {
          "name": "SNOWBOARD"
        },
        {
          "name": "SOFTBALL"
        },
        {
          "name": "SPEEDRIDING"
        },
        {
          "name": "SPINNING"
        },
        {
          "name": "SQUASH"
        },
        {
          "name": "SUBASTAS"
        },
        {
          "name": "SURF"
        },
        {
          "name": "TAEKWONDO"
        },
        {
          "name": "TAICHI"
        },
        {
          "name": "TAMPONES O SELLOS DE CAUCHO (CREACION CON)"
        },
        {
          "name": "TAROT"
        },
        {
          "name": "TATUAJES"
        },
        {
          "name": "TAXIDERMIA"
        },
        {
          "name": "TEJER (CON UN TELAR CASERO)"
        },
        {
          "name": "TEJO (JUEGO COLOMBIANO)"
        },
        {
          "name": "TENIS"
        },
        {
          "name": "TINTADO/TEÑIDO DE TEJIDOS"
        },
        {
          "name": "TIRO CON ARCO"
        },
        {
          "name": "TIRO CON TIRACHINAS"
        },
        {
          "name": "TIRO DE HACHA"
        },
        {
          "name": "TIRO DEPORTIVO CON ARMA DE FUEGO"
        },
        {
          "name": "TOCAR UN INSTRUMENTO MUSICAL"
        },
        {
          "name": "TOROS (AFICION A LOS)"
        },
        {
          "name": "TOSTAR CAFE"
        },
        {
          "name": "TRANSFERENCIA DE IMAGENES"
        },
        {
          "name": "TRENES A ESCALA"
        },
        {
          "name": "TRIATLON"
        },
        {
          "name": "TUFTING"
        },
        {
          "name": "UÑAS (DECORACION DE)"
        },
        {
          "name": "VEHICULOS DE CONTROL REMOTO (RC)"
        },
        {
          "name": "VELA DEPORTIVA Y RECREATIVA"
        },
        {
          "name": "VELAS (CREACION DE VELAS)"
        },
        {
          "name": "VIAJAR"
        },
        {
          "name": "VIAJAR LENTAMENTE (SLOW TRAVEL)"
        },
        {
          "name": "VIAJES DE AVENTURA"
        },
        {
          "name": "VIAJES TEMATICOS"
        },
        {
          "name": "VIDEO (REALIZACION DE VIDEOS)"
        },
        {
          "name": "VIDEOJUEGOS"
        },
        {
          "name": "VISITAR MONUMENTOS Y YACIMIENTOS ARQUEOLÓGICOS"
        },
        {
          "name": "VISITAR MUSEOS Y EXPOSICIONES"
        },
        {
          "name": "VISITAS URBANAS"
        },
        {
          "name": "VOLEIBOL"
        },
        {
          "name": "VOLEY PLAYA"
        },
        {
          "name": "VOLUNTARIADO AMBIENTAL"
        },
        {
          "name": "VOLUNTARIADO ANIMAL"
        },
        {
          "name": "VOLUNTARIADO CULTURAL"
        },
        {
          "name": "VOLUNTARIADO EN DEPORTE"
        },
        {
          "name": "VOLUNTARIADO SOCIAL"
        },
        {
          "name": "VUELO A VELA (VUELO SIN MOTOR)"
        },
        {
          "name": "WAKEBOARD"
        },
        {
          "name": "WATERPOLO"
        },
        {
          "name": "WINDSURF"
        },
        {
          "name": "YOGA"
        },
        {
          "name": "YOGA AEREO"
        },
        {
          "name": "YOUTUBER"
        },
        {
          "name": "ZUMBA"
        },
      ],
      socialMedia: [
        {
          title: "LinkedIn",
          icon: "fab fa-linkedin-in",
          active: false,
        },
      ],
      skills: [],
      lenguagesData: [
        {
          id: 1,
          "name": "Africano"
        },
        {
          id: 2,
          "name": "Alemán"
        },
        {
          id: 3,
          "name": "Arabe"
        },
        {
          id: 4,
          "name": "Asturiano"
        },
        {
          id: 5,
          "name": "Bahasa Indonesia"
        },
        {
          id: 6,
          "name": "Belarusian"
        },
        {
          id: 7,
          "name": "Bengalí"
        },
        {
          id: 8,
          "name": "Búlgaro"
        },
        {
          id: 9,
          "name": "Catalán"
        },
        {
          id: 12,
          "name": "Checo"
        },
        {
          id: 13,
          "name": "Chino"
        },
        {
          id: 10,
          "name": "Coreano"
        },
        {
          id: 11,
          "name": "Croata"
        },
        {
          id: 14,
          "name": "Danés"
        },
        {
          id: 15,
          "name": "Egipcio"
        },
        {
          id: 50,
          "name": "Español"
        },
        {
          id: 16,
          "name": "Estoniano"
        },
        {
          id: 17,
          "name": "Faeroese"
        },
        {
          id: 18,
          "name": "Finnish"
        },
        {
          id: 19,
          "name": "Francés"
        },
        {
          id: 20,
          "name": "Griego"
        },
        {
          id: 21,
          "name": "Guaraní"
        },
        {
          id: 22,
          "name": "Hebreo"
        },
        {
          id: 23,
          "name": "Holandés"
        },
        {
          id: 24,
          "name": "Húngaro"
        },
        {
          id: 25,
          "name": "Icelandic"
        },
        {
          id: 26,
          "name": "Indonés"
        },
        {
          id: 27,
          "name": "Indú"
        },
        {
          id: 28,
          "name": "Italiano"
        },
        {
          id: 49,
          "name": "Inglés"
        },
        {
          id: 29,
          "name": "Japonés"
        },
        {
          id: 30,
          "name": "Latín"
        },
        {
          id: 31,
          "name": "Latvian"
        },
        {
          id: 32,
          "name": "Lithuanian"
        },
        {
          id: 33,
          "name": "Malaysian"
        },
        {
          id: 34,
          "name": "Noruego"
        },
        {
          id: 49,
          "name": "Otro"
        },
        {
          id: 35,
          "name": "Polaco"
        },
        {
          id: 36,
          "name": "Portugués"
        },
        {
          id: 37,
          "name": "Rumano"
        },
        {
          id: 38,
          "name": "Ruso"
        },
        {
          id: 39,
          "name": "Serbio"
        },
        {
          id: 40,
          "name": "Slovak"
        },
        {
          id: 41,
          "name": "Slovenian"
        },
        {
          id: 42,
          "name": "Sueco"
        },
        {
          id: 43,
          "name": "Tailandés"
        },
        {
          id: 44,
          "name": "Tibetano"
        },
        {
          id: 45,
          "name": "Turco"
        },
        {
          id: 46,
          "name": "Ukranian"
        },
        {
          id: 47,
          "name": "Vasco"
        },
        {
          id: 48,
          "name": "Vietnamita"
        },
      ],
      tabs: [
        {
          title: "Datos personales",
          active: true,
          checked: true,
        },
        {
          title: "Información Escolar",
          active: false,
          checked: false,
        },
        {
          title: "Idiomas",
          active: false,
          checked: false,
        },
        {
          title: "Experiencia",
          active: false,
          checked: false,
        },
        {
          title: "Pasatiempos",
          active: false,
          checked: false,
        },
        {
          title: "Redes Sociales",
          active: false,
          checked: false,
        },
        {
          title: "Documentos",
          active: false,
          checked: false,
        },
      ],
    };
  },

  components: {
    InputPartials,
    UploadPartials,
  },

  methods: {
    async listEstado() {
      try {
        let resp = await UserService.getEstado();
        let { data } = resp.data;
        this.dataEntidad = data;
      } catch ($e) {
        console.log($e.message);
      }
    },
    async listMuni(estado) {
      try {
        const parametroCodificado = encodeURIComponent(estado);
        let resp = await UserService.getEstado({ estado: parametroCodificado });
        let { data } = resp.data;
        this.dataMunicipio = data;
      } catch ($e) {
        console.log($e.message);
      }
    },
    async listColonia(municipio) {
      try {
        const municipioCodificado = encodeURIComponent(municipio);
        let resp = await UserService.getEstado({ municipio: municipioCodificado });
        this.dataColonia = resp.data;
      } catch (error) {
        console.log("Error", error.message)
      }
    },

    changeComprobante(data) {
      this.filecomprobantes = data;
    },
    changeCv(data) {
      this.filecv = data;
    },
    activeTabs(i, checked = true) {
      if (!checked) return;
      this.tabs.forEach((el, index) => {
        if (i == index) {
          el.active = true;
          el.checked = true;
        } else {
          el.active = false;
        }
      });
    },
    async Formvalidate() {
      try {
        this.response.loading = true;
        var form = {
          ...this.state,
          status: this.$route.path.replace("/", ""),
          filecomprobantes: this.filecomprobantes,
          filecv: this.filecv,
        };
        const resp = await UserService.formStudents(form);
        const { data } = resp.data;
        let timerInterval;
        this.$swal({
          position: "top-center",
          icon: "success",
          title: "Formulario enviado correctamente.",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading()
            const b = this.$swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = this.$swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        });
        const user = JSON.parse(localStorage.getItem("user") || null);
        const accessToken = localStorage.getItem("accessToken") || null;
        user.roles = data.roles;
        user.register_complete = data.register_complete;
        user.token = accessToken;
        this.response.loading = false;
        localStorage.setItem("user", JSON.stringify(user));
        window.location.href = "/profile";
        return true;
      } catch (error) {
        this.response.loading = false;
        this.$swal({
          position: "top-end",
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        return false;
      }
    },
    submitForm() {
      if (this.filecv.length === 0) {
        this.response.loading = true
        let title = "Sube tu CV";
        this.alertUse("center", title, "warning");
        this.response.loading = false
      }
      else {
        this.response.loading = true
        this.Formvalidate();
      }
    },
    addSkill(e) {
      if (e.keyCode === 13 && this.tempSkill) {
        if (!this.skills.includes(this.tempSkill)) {
          this.skills.push(this.tempSkill);
        }
        this.tempSkill = "";
      }
    },
    deleteSkill(skill) {
      this.skills = this.skills.filter((item) => {
        return skill !== item;
      });
    },
    validarExt() {
      var archivoInput = document.getElementById("archivo");
      var archivoRuta = archivoInput.value;
      var extPermitidas = /(.pdf)$/i;
      if (!extPermitidas.exec(archivoRuta)) {
        alert("Asegurate de haber seleccionado un pdf");
        archivoInput.value = "";
        return false;
      }
    },
    showModal() {
      this.$swal({
        title: "Estudiante Universitario",
        icon: "info",
        html:
          '<center><p>Para poder completar existosamente tu registro necesitas contar con la siguiente información:<p>' +
          '<ol style="text-align: left;">' +
          '<li style="font-family: "Roboto", sans-serif;">TUS DATOS PERSONALES' +
          '<li style="font-family: "Roboto", sans-serif;">CURP</li>' +
          '<li style="font-family: "Roboto", sans-serif;">CV</li>' +
          '<li style="font-family: "Roboto", sans-serif;">Link de tu perfil de LinkedIn</li>' +
          "</ol></center>",
        confirmButtonText: 'Continuar <i class="fa fa-thumbs-up"></i> ',
        confirmButtonAriaLabel: "Thumbs up, great!",
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    onlyNumberSalary($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    addExperiencia() {
      if (this.counter <= 5) {
        ++this.counter,
          this.state.experiencia.push({
            experienciaId: `Empleo`,
            experienciaCompany: "",
            experienciaPuesto: "",
            experienciaSalario: "",
            experienciaFechaInicio: "",
            experienciaFechaFin: "",
            experecienciaLogros: "",
            requiredValue: true,
          });
      }
      if (this.counter >= 5) {
        let title = "SOLO PUEDES AGREGAR 5 EMPLEOS PREVIOS";
        this.alertUse("center", title, "warning")
      }
    },
    //Metodo para generar alertas
    alertUse(positionAlert, title, iconalert) {
      this.$swal({
        position: positionAlert,
        icon: iconalert,
        timer: 5000,
        html: "<center><p>" + title + "</p></center>",
        confirmButtonText: 'Continuar <i class="fa fa-thumbs-up"></i> ',
        confirmButtonAriaLabel: "Thumbs up, great!",
      });
    },
    agregateLenguage() {
      if (this.state.hobbies.length >= 10) return
      this.state.idiomas.push({
        idioma: '',
        nivel: ''
      })
    },
    agregateSports() {
      if (this.state.hobbies.length >= 10) return
      this.state.hobbies.push({
        name: '',
      })
    },
    deleteArray(indice, array) {
      if (array == "idiomas") {
        this.state.idiomas.splice(indice, 1);
      }
      else if (array == "experiencia") {
        this.state.experiencia.splice(indice, 1);
        --this.counter
      }
      else if (array == "hobbies") {
        this.state.hobbies.splice(indice, 1);
      }
    },
    editInf() {
      this.enableInput == true ? this.enableInput = false : this.enableInput = true;
    },
    saveTemplate() {
      let templateString = JSON.stringify(this.state);
      let user = JSON.parse(localStorage.getItem("user"));
      let formSelected = "Universitario";
      let { _id } = user;
      let templateSend = {
        data: templateString,
        studentId: _id,
        formSelected: formSelected,
      }
      UserService.templateUpdateForm(templateSend).then((response) => {
        this.$swal({
          position: "top-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        window.location.href = "/";
      })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e,
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  },
};
</script>
